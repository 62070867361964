import { PageProps, graphql } from 'gatsby';
import Layout from 'layouts/Layout';

import ResourceDetailLayout from 'components/ResourceDetailLayout';

import type {
  ContentfulComponentHeading,
  ContentfulComponentSwitchback,
  ContentfulTemplatePodcast,
  ContentfulTemplatePodcastConnection,
} from 'graphqlTypes';
import type { FC } from 'react';

interface PodcastDataType {
  podcastData: ContentfulTemplatePodcast;
  nextpodcastData: ContentfulTemplatePodcastConnection;
  cardsHeading: ContentfulComponentHeading;
  aboutTheShowSwitchback: ContentfulComponentSwitchback;
}

const Page: FC<PageProps<PodcastDataType>> = ({
  data: { aboutTheShowSwitchback, cardsHeading, podcastData, nextpodcastData },
  location,
}) => (
  <Layout slug={location.pathname} seo={podcastData.seo}>
    <ResourceDetailLayout
      {...podcastData}
      nextOne={nextpodcastData}
      displayBreadcrumbs={podcastData.seo?.displayBreadcrumbs}
      cardsHeading={cardsHeading}
      aboutTheShowSwitchback={aboutTheShowSwitchback}
    />
  </Layout>
);

export default Page;

export const pageQuery = graphql`
  query templatePodcastQuery($slug: String!) {
    podcastData: contentfulTemplatePodcast(slug: { eq: $slug }) {
      ...contentfulTemplatePodcast
    }
    nextpodcastData: allContentfulTemplatePodcast(
      sort: { fields: publishDate, order: ASC }
      filter: { slug: { ne: $slug } }
    ) {
      nodes {
        ...contentfulTemplatePodcast
      }
    }
    cardsHeading: contentfulComponentHeading(contentful_id: { eq: "4l1z7W0TAmrjtL4k1FIg36" }) {
      ...contentfulComponentHeading
    }
    aboutTheShowSwitchback: contentfulComponentSwitchback(contentful_id: { eq: "36UlANkzaOoLS9KqRAGDLf" }) {
      ...contentfulComponentSwitchback
    }
  }
`;
